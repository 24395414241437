import { FetchABTestsOfUsers } from '../services/student-api';
import { TEACHER_AB_TEST } from '../constant-data/constants';

export function setNewABTests(response) {
  const abTests = {};

  for (const key in response) {
    if (key.includes("::")) {
      const [typeName, abTestName] = key.split('::');

      if (!abTests.hasOwnProperty(typeName)) {
        abTests[typeName] = {};
      }
      abTests[typeName][abTestName] = response[key];
    } else {
      // If no "::" is present, store it as a simple key-value pair
      // e.g format -> "AB_PA:B2CP:NU:OB:AL:CrossPlatformAppDownload": "Variant"
      abTests[key] = response[key];
    }
  }
  saveInLS(abTests);
}

function saveInLS(resp) {
  try {
    localStorage.setItem('newABTests', JSON.stringify(resp));
  } catch (err) {
    return err;
  }
}

export function getABTestValue(userType, abTestName) {
  const abTests = JSON.parse(localStorage.getItem('newABTests') || "{}");
  if (!abTests) return null;

  // If userType is null means the key is stored directly at the root level (i.e., no "::" in original key) 
  // e.g format -> "AB_PA:B2CP:NU:OB:AL:CrossPlatformAppDownload": "Variant"
  if (!userType) {
    return abTests[abTestName] || null;
  }

  // If the userType(e.g Parent/Teacher) format with "::" exists and has the AB test stored inside
  if (abTests[userType] && abTests[userType][abTestName]) {
    return abTests[userType][abTestName];
  }
  return null;
}


export async function abTestsOfUsers(userIds) {
  const requestBody={users:userIds.filter((user)=>user.id!=="")}
 
try {
  const abTestsResponse = await FetchABTestsOfUsers(requestBody);
  const abTestsDetails = {};
  for (let key of userIds) {
    abTestsDetails[key.type] = abTestsResponse[key.id];
  }
  if(abTestsDetails.teacher){
    localStorage.setItem(TEACHER_AB_TEST, JSON.stringify(abTestsDetails.teacher));
  }
  else{
    localStorage.setItem(TEACHER_AB_TEST, null);
  }
  setNewABTests({...abTestsDetails.teacher, ... abTestsDetails.parent})
  return abTestsDetails;
} catch (err) {
  return err
}
}

export function getTeacherABTestValue(abTestName) {
  const abTests = JSON.parse(localStorage.getItem(TEACHER_AB_TEST) || null);
  if (abTests && abTests[abTestName]) {
    return abTests[abTestName];
  }
  return null;
}