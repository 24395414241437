var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { camelToTitleCase, generateUUID, getMemoryInfo, getPlayableClickedEventProps } from '../../js/utils/web-helper';
import { GOLD_EVENT_PROPERTIES } from '../constant-data/constants';
// Function to send Playable Clicked event
export var sendPlayableClickedEvent = function (props, gameMap, item, streakEventProps) {
    var _a;
    var streakData = {};
    if (streakEventProps) {
        streakData = changeObjectKeyCasingToTitleCase(streakEventProps);
    }
    else {
        // Extract URL params and merge them into props
        var extractedData = extractUrlParams(window.location.href);
        var parsedData = safeParseJson((_a = extractedData.event_properties) !== null && _a !== void 0 ? _a : {});
        addRecommendedRailProp(parsedData);
        streakData = changeObjectKeyCasingToTitleCase(parsedData);
    }
    props = __assign(__assign(__assign(__assign(__assign({}, props), streakData), { 'Playable Click Id': generateUUID() }), getMemoryInfo()), getPlayableClickedEventProps(gameMap, item));
    setGoldPropertiesInWindow(props);
    window.trackAnalyticsEvent('Playable Clicked', props);
};
// Function to set Gold properties in window
export var setGoldPropertiesInWindow = function (props) {
    try {
        window.gold_event_properties = getGoldProperties(props);
    }
    catch (error) {
        console.error('Error setting gold properties:', error);
    }
};
export function extractUrlParams(url) {
    var result = {};
    try {
        var urlObj = new URL(url);
        result.pathname = urlObj.pathname;
        // Extract hash parameters
        var hashPart = urlObj.hash.substring(1);
        if (hashPart) {
            var hashSplit = hashPart.split('?');
            result.hashPath = hashSplit[0];
            if (hashSplit.length > 1) {
                var hashQueryParams = new URLSearchParams(hashSplit[1]);
                hashQueryParams.forEach(function (value, key) {
                    result[key] = value;
                });
            }
        }
        // Extract regular query parameters
        urlObj.searchParams.forEach(function (value, key) {
            result[key] = value;
        });
        // Process concatenated parameters
        var fullUrl = url.split('#')[0].split('?')[1] || '';
        var hashContent = url.split('#')[1] || '';
        var combinedContent = fullUrl + (hashContent.includes('?') ? hashContent.split('?').slice(1).join('?') : '');
        var paramRegex = /([a-zA-Z0-9_]+)=([^=&]+)(?=&|$)/g;
        var match = void 0;
        while ((match = paramRegex.exec(combinedContent)) !== null) {
            var key = match[1], value = match[2];
            if (!result[key]) {
                result[key] = value;
            }
        }
        // Parse JSON objects after decoding all parameters
        for (var key in result) {
            var value = result[key];
            if (value && typeof value === 'string') {
                var decodedValue = void 0;
                try {
                    decodedValue = decodeURIComponent(value);
                }
                catch (decodeError) {
                    console.log("Failed to decode URI component for key ".concat(key, ": ").concat(decodeError instanceof Error ? decodeError.message : 'Unknown error'));
                    continue;
                }
                if (decodedValue.startsWith('{')) {
                    try {
                        result[key] = JSON.parse(decodedValue);
                    }
                    catch (parseError) {
                        console.log("Failed to parse JSON for key ".concat(key, ": ").concat(parseError instanceof Error ? parseError.message : 'Unknown error'));
                        result[key] = decodedValue; // Keep decoded value if parsing fails
                    }
                }
                else {
                    result[key] = decodedValue; // Update with decoded value
                }
            }
        }
        return result;
    }
    catch (error) {
        console.error("Error parsing URL:", error);
        return { error: error instanceof Error ? error.message : 'Unknown error' };
    }
}
// Function to process and filter properties
var getGoldProperties = function (props) {
    var updatedProps = changeObjectKeyCasingToTitleCase(props);
    // Filter properties based on GOLD_EVENT_PROPERTIES
    var result = {};
    GOLD_EVENT_PROPERTIES.forEach(function (key) {
        if (updatedProps[key] !== undefined) {
            result[key] = updatedProps[key];
        }
    });
    return result;
};
var changeObjectKeyCasingToTitleCase = function (props) {
    var updatedProps = {};
    for (var _i = 0, _a = Object.entries(props); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], value = _b[1];
        if (value !== undefined && value !== null) {
            // Convert key to Title Case
            var titleCaseKey = camelToTitleCase(key);
            updatedProps[titleCaseKey] = value;
        }
    }
    return updatedProps;
};
export var safeParseJson = function (data) {
    if (typeof data === "string") {
        try {
            return JSON.parse(data);
        }
        catch (error) {
            console.error("Invalid JSON:", error);
            return {};
        }
    }
    return data; // Return as-is if it's already an object
};
export var addRecommendedRailProp = function (props) {
    if (!props.recommendedRail) {
        props.recommendedRail = [];
        var recommendedRailName = props.recommendedRailName;
        var recommendedRailRank = props.recommendedRailRank;
        // Add the new combined values to recommendedRail
        if (recommendedRailName && recommendedRailRank && recommendedRailName.length === recommendedRailRank.length) {
            for (var i = 0; i < recommendedRailName.length; i++) {
                var combined = "".concat(recommendedRailName[i], "|").concat(recommendedRailRank[i]);
                props.recommendedRail.push(combined);
            }
        }
    }
};
export var getStreakInfoParamForUrl = function () {
    var _a, _b, _c, _d;
    var eventProps = (_a = extractUrlParams(window.location.href).event_properties) !== null && _a !== void 0 ? _a : {};
    if (Object.keys(eventProps).length > 0) {
        var railCode = (_b = eventProps.railCode) !== null && _b !== void 0 ? _b : '';
        var tabCode = (_c = eventProps.tabCode) !== null && _c !== void 0 ? _c : '';
        var tileCode = (_d = eventProps.tileCode) !== null && _d !== void 0 ? _d : '';
        return {
            rail_code: railCode,
            tab_code: tabCode,
            tile_code: tileCode,
        };
    }
    return {};
};
