import apiCaller from '../utils/api-caller';
const secrets = require('../../../public_keys.json');

const USER_DATA_STALE_TIME = secrets?.react_query_time_data?.USER_DATA_STALE_TIME || 0;

export async function ProfileApi(params) {
  return apiCaller({ path: '/web_api/student/v2/profile', params: params });
}


export async function fetchAllCoursesList(params) {
  return apiCaller({
    method: 'post',
    path: `${BASE_API_GATEWAY_URL}/dashboard-service/api/v1/fetchAllCourses`, params,
    extServiceFlag: true
  });
}



export async function CoursePlaylistApi(params) {
  const apiName = 'course_playlist'
  let response = await getAPIResponse(apiName, '/web_api/student/v2/course_playlist', params, params.shouldFetchCacheData)
  return response
}

export async function CourseSuggestionsApi(params) {
  const apiName = 'course_suggestions'
  let response = await getAPIResponse(apiName, '/web_api/student/v2/course_suggestions', params)
  return response
}

export async function CourseStatusApi(params) {
  const apiName = 'course_status'
  let response = await getAPIResponse(apiName, '/web_api/student/v2/course_status', params)
  return response
}

export function MFSettingsApi(params) {
  return apiCaller({ path: '/web_api/student/v1/math_fact_settings', params: params });
}

export function UpdateMFSettingsApi(params) {
  return apiCaller({ method: 'post', path: '/web_api/student/v1/math_fact_settings', params: params });
}

export function GetStudentTasks(params) {
  return apiCaller({ path: '/web_api/students/v1/tasks/get_student_tasks', params: params });
}

export function GetStudentFactFluencies(params) {
  if (params.grade_code.toUpperCase() !== "K") {
    return apiCaller({
      path: `${BASE_API_GATEWAY_URL}/school-service/api/v1/students/${params.student_id}/student-fact-fluencies`,
      extServiceFlag: true
    });
  } else {
    return new Promise(resolve => resolve(null))
  }
}
export function GetStudentFactFluenciesProgress(params, studentId, gradeCode) {
  if (gradeCode.toUpperCase() !== "K") {
    return apiCaller({ path: `${BASE_API_GATEWAY_URL}/exploratory-games-service/api/v1/teachers/math-fact-reports/students/${studentId}/user-date-wise-fact-attempt-count`, params: params, extServiceFlag: true });
  } else {
    return new Promise(resolve => resolve(null))
  }
}

export async function GetLearningPlans(params) {
  const apiName = 'learningplansApi'
  let response = await getAPIResponse(apiName, '/web_api/students/v1/student_diagnostic_test/learning_plans.json', params)
  return response
}


export function GetUserLoTask(params) {
  return apiCaller({ method: 'post', path: '' })// need to complete the api call here
}

export async function GetStudentAssessment(params) {
  const apiName = 'studentAssessmentApi'
  let response = await getAPIResponse(apiName, '/web_api/students/v1/student_diagnostic_test/assessments.json', params)
  return response
}

export function SetActiveCourse(params, hideSpinner) {
  return apiCaller({ method: 'post', path: '/web_api/student/v2/set_active_course', params: params, hideSpinner })
}

export function ChangeMode(params) {
  return apiCaller({ method: 'post', path: '/web_api/student/v2/change_mode', params: params })
}

export function GetContentGroups(params) {
  return apiCaller({ method: 'get', path: '/web_api/student/v2/topics', params: params })
}

export function StudentSettings(method = 'get', params, hideSpinner) {
  return apiCaller({ method: method, path: '/user-setting', params: params, hideSpinner });
}

export function SetWeeklyGoalsPopupLastShown() {
  return apiCaller({ method: 'get', path: '/web_api/student/v2/set_weekly_goals_popup_last_shown' });
}

export function AbTestDetails(params) {
  return apiCaller({ method: 'get', path: '/ab_test_details', params: params });
}

export function FetchMilestoneLOStatus(params) {
  return apiCaller({ method: 'get', path: '/web_api/reporting_service/v1/student_report/fetch_milestone_lo_status', params: params });
}

export function FetchWeeklyReport(params) {
  return apiCaller({ method: 'get', path: '/web_api/reporting_service/v1/student_report/fetch_weekly_report', params: params });
}

export function GetWeeklyWidget() {
  return apiCaller({ method: 'get', path: '/web_api/student/v2/weekly_widget' });
}

export function VerifyPTLSignupAPI(params) {
  params.rejectWithErrorObject = true;
  return apiCaller({
    method: 'post',
    path: '/verify_classroom_linked_signup_params',
    params: params,
    allow_non_ok_response: true
  });
}

export function CreateParentAPI(params) {
  document.cookie = "signup_page_url" + "=" + window.location.pathname + "; path=/";
  return apiCaller({ method: 'post', path: '/users', params: params });
}

export function ClassroomLinkedIssues(params) {
  return apiCaller({ method: 'post', path: '/classroom_linked_signup_issues', params: params });
}

/**
 * @Note : Currently, this api is available only for PTL parents. Please don't use this for B2C parents.
 */
export function FetchABTests(params) {
  return apiCaller({ method: 'get', path: '/web_api/students/v1/guardians/ab_tests', params: params });
}

export function FetchMultiplayerGameSeedData(params) {
  return apiCaller({ method: 'get', path: `${BASE_API_GATEWAY_URL}/multiplayer-service/v1/games`, params: params, extServiceFlag: true });
}

export function CreateGameRoom(params, rejectWithErrorObject) {
  return apiCaller({ method: 'post', path: `${BASE_API_GATEWAY_URL}/multiplayer-service/v1/game_rooms`, params: { ...params, rejectWithErrorObject }, extServiceFlag: true });
}

export function FetchPlayersData(params, hideSpinner) {
  return apiCaller({ method: 'get', path: `${BASE_API_GATEWAY_URL}/mps/v1/students/${params.studentId}/players`, hideSpinner, extServiceFlag: true });
}

export function FetchBotsData(hideSpinner) {
  return apiCaller({ method: 'get', path: `${BASE_API_GATEWAY_URL}/bot-service/api/v1/bots`, hideSpinner, extServiceFlag: true });
}

export function GetAllInvites(params, hideSpinner) {
  return apiCaller({ method: 'get', path: `${BASE_MPSJ_URL}/mps/v1/game_room_invites/invites`, params: params, hideSpinner, extServiceFlag: true })
}

/**
 * Major difference between this and above api :
 * - Above api only fetches not seen invites i.e. invites already sent to FE are not sent in next api call
 * - This api fetches all non-expired invites (seen or not seen)
 */
export function GetAllInvitesPersistent(params, hideSpinner) {
  return apiCaller({ method: 'get', path: `${BASE_API_GATEWAY_URL}/multiplayer-service/v1/game_rooms`, params: params, hideSpinner, extServiceFlag: true })
}

export function JoinGameInvite(params, rejectWithErrorObject) {
  return apiCaller({ method: 'post', path: `${BASE_API_GATEWAY_URL}/multiplayer-service/v1/game_rooms/` + params.id + '/join', params: { game_room_invite_id: params.game_room_invite_id, client_id: params.client_id, rejectWithErrorObject }, extServiceFlag: true })
}

export function MarkFtueSeen(params, hideSpinner) {
  return apiCaller({ method: 'post', path: `/web-service/v1/students/${params.id}/mark_ftue_seen`, params: params, hideSpinner });
}

export function MarkNudgeShownToTeacher(params, hideSpinner) {
  return apiCaller({ method: 'post', path: `/web_api/common/student/v1/mark_nudge_shown_to_teacher`, params: params, hideSpinner })
}
export function TrackActionAnalytics(params) {
  return apiCaller({ method: 'post', path: '/web_api/student/v2/track_action_analytics', params: params, hideSpinner: params.hideSpinner });
}

export function FetchMultiplayerGameSeedDataPlayzone(params) {
  return apiCaller({ method: 'get', path: `${BASE_API_GATEWAY_URL}/multiplayer-service/v2/games`, params: params, extServiceFlag: true });
}


export function FetchABTestsOfUsers(params) {
  return apiCaller({
    method: 'post', path: `${BASE_API_GATEWAY_URL}/abtest-service/api/v1/users/get-all-assigned-ab-tests-of-users`, params: params,
    extServiceFlag: true
  });
}

export function SendDailyLimitReachedEvent(params) {
  return apiCaller({ path: '/web_api/student/v2/daily_limit_reached_event', params: params, restrictConversionToJson: true });
}

export function CanPlayFromCurriculum(params) {
  return apiCaller({ method: 'post', path: `${BASE_API_GATEWAY_URL}/curriculum_service/public/api/v1/can_play_post`, params: params, extServiceFlag: true })
}

export function CheckUserCanPlayPlayzoneGame(params) {
  return apiCaller({ path: `${BASE_API_GATEWAY_URL}/multiplayer-service/can_play`, params: params, extServiceFlag: true });
}

export function ProfileLiteApi(params) {
  return apiCaller({ path: '/web_api/student/v2/profile_lite', params: params, doNotRedirect: true });
}

export function GetTutorDetails(params) {
  return apiCaller({ path: `${BASE_API_GATEWAY_URL}/live-class/tutor-portal/profile`, params: params, extServiceFlag: true });
}

export function ValidateAndCheckContentApi(params) {
  return apiCaller({
    path: '/web_api/student/v2/validate_and_check_content', params: params, requestHeaders: {
      'Cache-Control': 'no-cache'
    }
  });
}

export function SetDiscountTimerInfo(params, hideSpinner) {
  return apiCaller({ method: 'post', path: `/web_api/parent/v1/profile/set_discount_timer_info`, params: params, hideSpinner })
}

export function getLiveClassJoinInfo(params, hideSpinner) {
  return apiCaller({ method: 'get', path: `${BASE_API_GATEWAY_URL}/live-class/student/live_class_plan`, params: params, hideSpinner, extServiceFlag: true })
}

export function setLiveClassPreferredTime(params, parentId) {
  return apiCaller({ method: 'post', path: `${BASE_API_GATEWAY_URL}/live-class/users/live_class_preferred_time_slot?parent_id=${parentId}`, params: params, extServiceFlag: true });
}

export function getWorksheetDetails(params) {
  return apiCaller({ method: 'get', path: `${BASE_API_GATEWAY_URL}/cms-service/public/api/v1/search-contents/search-by-content-code`, params: params, extServiceFlag: true });
}

export function catexMigrationGetRequest() {
  return apiCaller({ path: '/web_api/student/v2/can_show_catex_migration_popup' });
}

export function catexMigrationPostAPI() {
  return apiCaller({ method: 'post', path: '/web_api/student/v2/catex_migration_popup_shown' });
}

export function setLiveClassAnswer(params, hideSpinner) {
  return apiCaller({ method: 'post', path: `${BASE_API_GATEWAY_URL}/live-class/user/class_question_answer`, params: params, hideSpinner, extServiceFlag: true })
}

export function canShowPtlPitchPage(hideSpinner) {
  return apiCaller({ method: 'post', path: `/web_api/student/v2/can_show_ptl_pitch_page`, hideSpinner })
}

export function ptlPitchShownToUser(hideSpinner) {
  return apiCaller({ method: 'post', path: `/web_api/student/v2/ptl_pitch_shown`, hideSpinner })
}

export async function getAPIResponse(storageKeyName, apiPath, params, shouldFetchCacheData = true) {
  let jsonKey = getApiJsonKey(storageKeyName)
  if (shouldCacheResponse() && shouldFetchCacheData) {
    if (hasApiResponseExpired(storageKeyName)) {
      setApiLastUpdateTimeStamp(storageKeyName);
      let key = getApiLastUpdateTimestamp(storageKeyName)
      let jsonResponse = await apiCaller({ path: apiPath, params: params });
      localStorage.setItem(jsonKey, JSON.stringify(jsonResponse));
      localStorage.setItem(key, new Date());
    }
    return new Promise(resolve => resolve(JSON.parse(localStorage.getItem(jsonKey))));
  } else {
    return apiCaller({ path: apiPath, params: params });
  }
}

export function getApiLastUpdateTimestamp(apiName) {
  return `${apiName}_${getStudentId()}_timestamp`;
}

export function getApiJsonKey(apiName) {
  return `${apiName}_${getStudentId()}_json`;
}

export function hasApiResponseExpired(apiName) {
  let waitTime = Math.round(USER_DATA_STALE_TIME / 1000);
  let current_timestamp = new Date();

  const key = getApiLastUpdateTimestamp('dashboard_dirty')
  const apiKeyTimestamp = getApiLastUpdateTimestamp(apiName)

  let dashboard_dirty_timestamp = localStorage.getItem(key);
  let apiCallTimeStamp = localStorage.getItem(apiKeyTimestamp);

  let differenceInSeconds = Math.abs(current_timestamp - new Date(apiCallTimeStamp)) / 1000;

  if (dashboard_dirty_timestamp == null && (differenceInSeconds > waitTime)) {
    return true;
  }
  else if (apiCallTimeStamp == null || (dashboard_dirty_timestamp != null && new Date(dashboard_dirty_timestamp) > new Date(apiCallTimeStamp)) || (differenceInSeconds > waitTime)) {
    return true;
  } else {
    return false;
  }
}

export function setApiLastUpdateTimeStamp(apiName) {
  let current_timestamp = new Date();
  const key = getApiLastUpdateTimestamp(apiName)
  localStorage.setItem(key, current_timestamp);
}

// Caching is enabled only for b2b students
// as web sockets are not supported for b2c as of now.
export const shouldCacheResponse = () => {
  if (JSON.parse(localStorage.getItem("is_classroom_student"))) {
    return true;
  }
  return false
}

export const getStudentId = () => {
  const Store = require('../stores/student-dashboard').Store;
  const studentId = (Store.getState().student.id) || sessionStorage.getItem('student_id');
  return studentId;
}

export const getKlassId = () => {
  const Store = require('../stores/student-dashboard').Store;
  const klassId = Store.getState().student.klass_id || sessionStorage.getItem('klass_id');
  return klassId;
}

export const deleteDashboardDirtyKey = () => {
  for (var key in localStorage) {
    if (
      localStorage.hasOwnProperty(key) &&
      (key.includes('_timestamp') || key.includes('dashboard_dirty') || key.includes('_json'))
    ) {
      localStorage.removeItem(key);
    }
  }
}


export function addStudentAndLogin(params, hideSpinner) {
  return apiCaller({ method: 'post', path: `/add_student_and_login`, params:params, hideSpinner })
}

export function getPlayableStreakInfo(params) {
  return apiCaller({ method: 'get', path: `${BASE_API_GATEWAY_URL}/curriculum_service/public/api/v1/streak/get_tile_info`, params: params, extServiceFlag: true })
}


