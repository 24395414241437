import { Store } from '../stores/student-dashboard';
import { Store as ParentStore} from '../stores/parent-dashboard';

import { getFreemiumAbTestAssignedSegment, isIOS, isMobileDevice, isMobileOrIpad, 
  getDRSLiveClassesTrialAbTestAssignedSegment, getCookie, fetchDiffInMinsBetweenTimestamps,
  getUserCountryCode, fetchHighestUserGrade, isSchoolHoursAvailable, 
  isTabletDevice, isOldPlanTierAssigned } from './web-helper';
import { ActivityGamesClasses, multiplayer_classes, dateForActivityGamesChanges } from '../constant-data/multiplayer-data';
import { getABTestValue, getTeacherABTestValue } from './ab-tests';
import { freemium_supported_grades } from '../constant-data/freemium-interface';
import { FREEMIUM_NO_BLOCKER_TIME_IN_MINUTE, PRE_K_GRADE } from '../constant-data/constants';

export const hasGamification = () => {
  return true;
}

export const hasC1Enabled = () => {
  const { settings } = Store.getState();
  if(settings) {
    return settings.is_classroom_student ? true: settings.c1_enabled;
  }
}

export const isPaymentElementEnabled = (productSettings) => {

  if (productSettings?.stripePaymentMethods && productSettings.stripePaymentMethods.length > 0) {
    return true
  }

  const parentEnabled = () => {
    return getABTestValue('Parent', 'StripePaymentFormType') === 'variant';
  }

  // if student is linked to parent and lands on purchase page use teacher ab test
  const parentEnabledByTeacher = () => {
    const {abTests} = ParentStore.getState();
    if (abTests && abTests.teacher) {
      return abTests.teacher["Teacher::StripePaymentFormType"] == 'variant'
    }
  }

  const testEnabled = () => {
    let stripeElement = window.localStorage.getItem('stripeElement');
    return stripeElement && stripeElement === 'variant';
  }

  const teacherEnabled = () => {
    const {abTests} =  Store.getState();
    if(abTests && abTests.teacher) {
      return abTests.teacher["Teacher::StripePaymentFormType"] === 'variant';
    }
  }

  return parentEnabled() || teacherEnabled() || parentEnabledByTeacher() || testEnabled();
}

// checking whether gamification dashboard to be shown or not for b2b
export const gamificationDashboardForBtb = (params) => {
  const { student } = params ? params : Store.getState();
  if(student) {
    return student.has_splashverse_enabled;
  }
}

// Method to check the cookies of web-wiew exist or not
export const isWebView = () => {
  const cookiesList = document.cookie.split(';');
  if(sessionStorage.getItem('webview')){
    return true;
  }
  if(cookiesList.length > 0) {
    let index = cookiesList.findIndex(x => x.includes('web_view'));
    if(index !== -1) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

/**
 * @param {*} params Ab test details fetched in profile api
 */
export const hasC4Enabled = (params) => {
  const { student, abTests } = params ? params : Store.getState();
  // this is done to support app web-view where we are directly opening play with friends
  if(isWebView()) {
    return true;
  }
  if(abTests && abTests.teacher) {
    const abtestSegment = abTests.teacher["Teacher::GamificationC4Teacher"]
    if (!abtestSegment || abtestSegment == 'Gamification_C4_Off') return false
    else return multiplayer_classes.includes(student.classroom_grade) && !(isMobileOrIpad() || isIOS());
  }
}

// Method to check whether Behaviour Quest Enabled or not
export const hasBQEnabled = () => {
  const behavioral_quest_enabled = getABTestValue("Parent","BQ") === "bq_on" ? true : false
   const { current_course } = Store.getState();
  if(current_course && current_course.grade_code) {
    return behavioral_quest_enabled && current_course.grade_code !== 'pk';
  }
  return false;
}

export const hasAccessToOneVsOne = () => {
  const {abTests} =  Store.getState();
  if(abTests && abTests.teacher) {
    const abTestSegment = abTests.teacher["Teacher::GamificationC4OneVsOneTeacher"]
    return ['C4_1v1_New_Variant', 'C4_1v1_Old_Variant'].includes(abTestSegment);
  }
  return false;
}

export const hasAccessToFreemium = (params) => {
  const { student, abTests } = params ? params : Store.getState();
  const parentFreemiumAbTestAssigned = getFreemiumAbTestAssigned();
  const teacherFreemiumAbTestAssigned = abTests && abTests.teacher && abTests.teacher['Teacher::Freemium_Topics_Out'];

  const parentEligibleForFreemium = !student.is_classroom_linked && (parentFreemiumAbTestAssigned && parentFreemiumAbTestAssigned !==  "Topics_Out_Off") ;
  const teacherEligibleForFreemium = student.is_classroom_linked && (teacherFreemiumAbTestAssigned && teacherFreemiumAbTestAssigned !== "Topics_Out_Off");

  return !isMobileDevice() && (parentEligibleForFreemium || teacherEligibleForFreemium);

}

export const getFreemiumAbTestAssigned = () =>{
  const {parentFreemiumAbTestAssignedSegment, teacherFreemiumAbTestAssignedSegment} = getFreemiumAbTestAssignedSegment() || {}

  const { settings } = Store.getState();
  if(settings.is_classroom_student) {
    return teacherFreemiumAbTestAssignedSegment;
  }
  else{
    return parentFreemiumAbTestAssignedSegment;
  }
}

export const isFreemiumDRSAssigned = () => {
  const segmentName = getFreemiumAbTestAssigned();
  if((segmentName === "Topics_Out_On_DRS" || segmentName === "DRSWithLiveClasses") && !isMobileDevice())
    return true;
  else
    return false;
}

export const canSeeSplashArenaTabOnFreemium = () => { //if any subject falls in eligibilty
  const { student, courses } = Store.getState();
  if (student.is_classroom_linked) {
    return freemium_supported_grades.includes(student.classroom_grade);
  }
  else {
    for (const item of courses) {
      if (freemium_supported_grades.includes(item.grade_code)) {
        return true;
      }
    }
    return false;
  }
}

// to check eligible grades for activity games i.e pk, k and 1
const parentGradeEligibleActivityGames = (courses) => {
  if (courses) {
    for (const item of courses) {
      if (ActivityGamesClasses.includes(item.grade_code)) {
        return true;
      }
    }
  }
  return false;
}

export const hasAccessToActivityGames = (params) => {
  if (isWebView())
    return false;
  const { courses, current_user } = params ? params : Store.getState();
  const userCreatedDate = new Date(current_user?.created_at);
  const effectiveActivityGamesDate = new Date(dateForActivityGamesChanges);
  const isActivityGames = userCreatedDate > effectiveActivityGamesDate;
  return isActivityGames && parentGradeEligibleActivityGames(courses);
}

// for old gamification user return true
export const toShowLiveClassTabOnGamification = () => {

  /// Not displaying live class in school hours
  if(isSchoolHoursAvailable()){
    return false;
  }

  // Available for US and Canada user only
  if(isWebView()){
    return false
  }
  let userCountry = getUserCountryCode() || "IN"
  // show t2 tab for all user on staging
  if(location.href.indexOf('staging-1') != -1){
    userCountry = 'US';
  }
  if(userCountry === 'US' || userCountry === 'CA'){
    return true;
  }
  else{
    return false;
  }
}

export const getDRSLiveClassAbTestAssigned = () =>{
  const {parentDRSLiveClassesTrialAbTestAssignedSegment, teacherDRSLiveClassesTrialAbTestAssignedSegment} = getDRSLiveClassesTrialAbTestAssignedSegment();
  const { settings } = Store.getState();
  if(settings.is_classroom_student) {
    return teacherDRSLiveClassesTrialAbTestAssignedSegment;
  }
  else{
    return parentDRSLiveClassesTrialAbTestAssignedSegment;
  }
}

/// Checks whether Flutter DRS AB test assigned or not
export const hasAccessToFlutterDRS = () =>{
  const { abTests } =  Store.getState();
  const parentDRSFlutterABTest = getABTestValue('Parent','DRSFlutter') || "";
  const teacherDRSFlutterABTest = abTests && abTests?.teacher && abTests.teacher['Teacher::DRSFlutter'] || "";

  const flutterSegment = "Flutter";
  let isAssigned = false;

  if (parentDRSFlutterABTest == flutterSegment || teacherDRSFlutterABTest == flutterSegment) {
    isAssigned = true;
  }
  localStorage.setItem("isFlutterUser", isAssigned);
  return isAssigned;
}

export const hasTimeExceedInFreemiumNoBlocker = () => {
  const { student } = Store.getState();
  if(student && student.id) {
    const studentExistInCookie = getCookie(`NoBlocker_${student.id}`);
    const studentObj = studentExistInCookie && JSON.parse(studentExistInCookie);
    if(studentExistInCookie && studentObj && studentObj.startTime != 0 && fetchDiffInMinsBetweenTimestamps(studentObj.startTime, new Date().getTime()) >= FREEMIUM_NO_BLOCKER_TIME_IN_MINUTE) {
      return true;
    }
  }
  return false;
}

export const hasAccessToTeacherFreemiumNoBlocker = () => {
  return false;
  // const { abTests } = Store.getState();
  // let isAbTestValue = false;
  // if(abTests && abTests.teacher) {
  //   let abTestSegment = abTests.teacher['Teacher::FreemiumNoBlocker'];
  //   if(abTestSegment == 'FreemiumNoBlocker' && ((isFreemiumDRSAssigned() && !isMobileDevice()) || (hasGamification() && !isTabletDevice() && !isMobileDevice()))) {
  //     isAbTestValue = true;
  //     const { settings } = Store.getState();
  //     if(settings.via_teacher_student_center) {
  //       setCookieWithAge("freemiumNoBlockerViaTeacherStudentCenter", true)
  //     }
  //   }
  // }
  // return isAbTestValue;
}

export const isDRSWithCategoryExpansion = () => {
  const { abTests,settings } = Store.getState();
  const isAbTestValueParent = getABTestValue("Parent","DRSCategoryExpansion") === "Variant" ? true : false;
  const isAbTestValueTeacher = abTests && abTests.teacher && abTests.teacher["Teacher::DRSCategoryExpansion"] === "Variant" ? true : false;

  if(settings.is_classroom_student) {
    return isAbTestValueTeacher;
  }
  return isAbTestValueParent;
}

export const hasSBCertificateAccess = () => {
  const { wonSBCertificate, settings } = Store.getState();
  if(!isWebView() && settings?.is_classroom_student && (wonSBCertificate?.length > 0)) {
    return true;
  }
  return false;
}

export const isScrollTrackingAbTestAssigned = () => {
  const isAbTestValue = getABTestValue("Parent","ScrollTracking") === "ScrollTracked" ? true : false;
  if(isAbTestValue) {
    return true;
  }
  return false;
}

// Whether DRS dashboard creation required or not
export const isEligibleForDRSDashboard = () =>{
  return hasAccessToFreemium() || hasAccessToFlutterDRS();
}

export const hasHomeSchoolScreenVariant = () => {
  // const { abTests } = Store.getState();
  // if(abTests && abTests.teacher) {
  //   let abTestSegment = abTests.teacher['Teacher::HomeSchoolScreen'];
  //   if(abTestSegment == "Variant") {
  //     return true
  //   }
  //   else {
  //     return false
  //   }
  // }
  return true
}

export const hasSummerLearningWithoutDiscountVariant = () => {
  const { abTests } = Store.getState();
  if(abTests && abTests.teacher) {
    let abTestSegment = abTests.teacher['Teacher::SummerLearningWithoutDiscount'];
    if(abTestSegment == "Variant") {
      return true
    }
    else {
      return false
    }
  }
  return false
}


// condition to show Home School Popup on login via klass code
export const showHomeSchoolScreen = ({viaTeacherStudentCenter, loggedInGuardianType, isClassroomStudent, from}) => {
  return hasHomeSchoolScreenVariant() && !viaTeacherStudentCenter && (loggedInGuardianType === "teacher") && isClassroomStudent
}

// abtest for new plan page
export const hasPremiumLive = () => {
  const segment = getABTestValue("Parent", "Premium_live_plan");
    if(isMobileDevice() && !hasAccessToFlutterDRS()){
      return false;
    }
    if(isTabletDevice() && !(hasAccessToFlutterDRS() || hasAccessToFreemium())){
      return false
    }
   return  (segment === "Variant" || segment === "Add_On");
}

export const hasTeacherPremiumLive = () =>{
  /// for mela dashboard not showing t2 purchase page
  if(isMobileDevice() && !hasAccessToFlutterDRS()) {
    return false;
  }
  return getTeacherABTestValue("Teacher::Premium_live_plan") === "Variant";
}

/// This function tells whether to show or not T2 purchase page.
/// if grade is not provided in param selected student grade will consider on the basis of course
export const toShowT2PurchasePage = () => {
  const isB2CUser = localStorage.getItem('is_classroom_student') !== 'true';
  if(isB2CUser){
    return true;
  }
  else{    
    return !isOldPlanTierAssigned();
  }
};

export const showActivityTextForT2Purchase = (studentId) => {
  if ((fetchHighestUserGrade(studentId) != PRE_K_GRADE) && toShowT2PurchasePage()) {
    return true;
  }
  return false;
};

export const isDynamicPositioningAssigned = () => {
  const { abTests, settings } = Store.getState();
  const isAbTestValueParent = getABTestValue("Parent", "DynamicPositioning") === "Variant" ? true : false;
  const isAbTestValueTeacher = abTests && abTests.teacher && abTests.teacher["Teacher::DynamicPositioning"] === "Variant" ? true : false;

  if (settings.is_classroom_student) {
    return isAbTestValueTeacher;
  }
  return isAbTestValueParent;
}

export const isNewCoqAssigned = () => {
  const isAbTestValue = getABTestValue("Parent","NewCOQs") === "Variant" ? true : false;
  if(isAbTestValue) {
    return true;
  }
  return false;
}

/// abtest to show pitch page before purchase page for ptl user
export const showPitchPageForRepeatedUsers = () => {
  if(localStorage.getItem('isPtlUser') == 'true'){
    return true;
  }
  else{
    return false;
  }
}

export const isPasswordlessloginUser = () => {
  return getTeacherABTestValue("Teacher::PasswordLessLogin") === "variant";
}

export const isRemoteLoginUser = () => {
  return getTeacherABTestValue("Teacher::RemoteLogin") === "variant";
}

export const isFlutterOnboardingAssigned = () => {
  const { settings } = Store.getState();
  if (settings.is_classroom_student) {
    return false;
  }
  const segmentName = getABTestValue("Parent","FlutterOnboarding");
  const isAbTestValue = (segmentName !== null && segmentName !== "Control") ? true : false; /// Will change ab test name upon creation
  if(isAbTestValue) {
    return true;
  }
  return false;
}

export const isDashboardCachingEnabled = () => {
  return getTeacherABTestValue("Teacher::DashboardCaching") === "Variant";
}

export const isB2BRepeatUser = () => {
  return getTeacherABTestValue("Teacher::RepeatUsersDiscount") === "Variant";
}

export const isFlutterTodos = () => {
  return getTeacherABTestValue("Teacher::FlutterTodos") === "Variant";
}

export const isBookLibraryP3Variant = () => {
  return (getTeacherABTestValue("Teacher::BookLibrary") === "Variant");
}

export const toShowStripePaymentElement = () => {
  const { settings } = Store.getState();
  if (settings.is_classroom_student) {
    return false;
  }
  const segment =
    getABTestValue('Parent', 'NewPaymentMethods');
  if (segment === 'variant_stripe') {
    return true;
  }

  /// to enable stripe payment element in discount flow
  if (segment === 'variant_paypal') {
    return true;
  }
  return false;
};

export const toShowStripePaymentElementWithPaypal = () => {
  const { settings } = Store.getState();

  /// Now we are not handle discount flow in paypal
  if (settings.is_classroom_student) {
    return false;
  }
  const segment =
    getABTestValue('Parent', 'NewPaymentMethods') === 'variant_paypal';
  if (segment) {
    return true;
  }
  return false;
};

export const isPL19VariantPremiumAssigned = () => {
  const isPtlUser = localStorage.getItem('isPtlUser') == 'true' || false;
  const segment = getABTestValue("Parent", "Premium_Live_$19") === "Variant_Premium";
  if(segment && !isPtlUser) {
    return true;
  }
    return false;
}

export const getAvatarGamificationSegment = () => {
  if((isMobileDevice() || isTabletDevice()) && !isWebView()){ // disabled for mobile and tablet, enabled for APP
    return 'Control';
  }
  return getABTestValue("Parent", "Gamification_v1");
}

export const isAvtarGamificationAssigned = () => {
  return getAvatarGamificationSegment() === "Variant";
}

export const isFlutterPlayableLauncherAssigned = () => {
  let abtestValue = getABTestValue('Parent', 'FlutterPlayableLauncher');
  let abtestValueTeacher = getABTestValue('Teacher', 'FlutterPlayableLauncher');
  return  (abtestValue != null && abtestValue.toLowerCase() === 'variant') || 
  (abtestValueTeacher != null && abtestValueTeacher.toLowerCase() === 'variant');
}

export const isGameHeaderV1Assigned = () => {
  return getABTestValue("Parent","GameHeaderV1") === "Variant";
}

export const isRenderingBuildWithoutIframe = () => {
  const { abTests, settings } = Store.getState();
  const isAbTestValueParent = getABTestValue("Parent", "RenderingBuildWithoutIframe") === "variant" ? true : false;
  const isAbTestValueTeacher = abTests && abTests.teacher && abTests.teacher["Teacher::RenderingBuildWithoutIframe"] === "variant" ? true : false;

  if (settings.is_classroom_student) {
    return isAbTestValueTeacher;
  }
  return isAbTestValueParent;
}

/// Create this fn. here to remove cyclic dependency
const unlimitedAccessOfferingsCount = (offerings) => {
  const UNLIMITED = 'unlimited';
  // here offerings is from tiered offering api
    // if offerings is null in params then fetch offerings value from local storage
    if(!offerings) {
        let settings = JSON.parse(localStorage.getItem('user_settings') || 'false') || {}; //it is from profile api
        offerings = settings?.current_tiered_offering?.offerings;
    }
    // Grouped 'lp' and 'downloadable_worksheets' into a single offering
    const groupedOfferings = {
        lp_and_worksheets: offerings?.lp == UNLIMITED || offerings?.downloadable_worksheets == UNLIMITED,
        live_class: offerings?.live_class == UNLIMITED,
        books: offerings?.books == UNLIMITED,
    };
    // Count the number of 'unlimited' offerings
    const unlimitedCount = Object.values(groupedOfferings).filter(value => value).length;
    return unlimitedCount;
}

export const isFlutterSettingsAssigned = () => {
  const { settings } = Store.getState();
  if (settings.is_classroom_student) {
    return false;
  }
  return JSON.parse(localStorage.getItem('is_flutter_settings_assigned'));
}

export const isCMPVariant = () => {
  return window.new_consent_flow;
}

export const showCrossPlatformScreen = () => {
  const isAbTestValue = getABTestValue(null, "AB_PA:B2CP:NU:OB:AL:CrossPlatformAppDownload") === "Variant" ? true : false;
  return isAbTestValue;
}

// Utility functions and shared logic
const getCommonVariantSegmentLogic = () => {
  const tieredOfferingData = JSON.parse(localStorage.getItem('tiered_offerings') || '{}');
  const currentOfferings = tieredOfferingData?.current_tiered_offering || null;
  const parentGuardian = tieredOfferingData?.settings?.currentUser?.type === 'parent';

  return {
    currentOfferings,
    parentGuardian,
    hasAnyOneOfferingAccess: unlimitedAccessOfferingsCount(currentOfferings?.offerings) > 0
  };
};

const getBookMonetizationSegments = (validSegmentValues) => {
  const isSegmentEnabledForUser = (userType, abTestKey) =>
    validSegmentValues.includes(getABTestValue(userType, abTestKey));

  const parentSegmentEnabled = isSegmentEnabledForUser("Parent", "OB_BookMonetisation_New") ||
                        isSegmentEnabledForUser("Parent", "OB_BookMonetisation_Old");
  const teacherSegmentEnabled = isSegmentEnabledForUser("Teacher", "OB_BookMonetisation_New") ||
                         isSegmentEnabledForUser("Teacher", "OB_BookMonetisation_Old");
  const parentB2CSegmentEnabled = isSegmentEnabledForUser("Parent", "OB_BookMonetisation");

  return {
    parentSegmentEnabled,
    teacherSegmentEnabled,
    parentB2CSegmentEnabled
  };
};

// Exported functions
export const isProductStructureAssigned = () => {
  const { parentGuardian, hasAnyOneOfferingAccess } = getCommonVariantSegmentLogic();
  const segmentKeysForProductStructure = ["Control_Product_Structure", "Variant_Product_Structure"];
  const { parentSegmentEnabled, teacherSegmentEnabled, parentB2CSegmentEnabled } =
    getBookMonetizationSegments(segmentKeysForProductStructure);

  if (parentGuardian && hasAnyOneOfferingAccess) {
    return parentSegmentEnabled || parentB2CSegmentEnabled;
  }

  // There might be a delay in assigning book monetization abtest to parent but multi pack plan code gets assigned to that student
  // so to handle that edge case have added both user types check
  // apart from the above mentioned edge case all other cases would also be handled with it
  return parentSegmentEnabled || parentB2CSegmentEnabled || teacherSegmentEnabled;
};

export const isBooksMonetizationVariant = () => {
  // Check if books app user first
  const firstAppShortcode = JSON.parse(localStorage.getItem('user_student'))?.first_app_shortcode;
  const isBooksAppUser = isWebView() && (firstAppShortcode?.toLowerCase() === "smunbk");

  if (isBooksAppUser) {
    return true;
  }

  // Check variant assignment
  const { parentGuardian, hasAnyOneOfferingAccess } = getCommonVariantSegmentLogic();
  const segmentKeysForBooksMonetization = ["Variant", "Variant_Product_Structure"];
  const { parentSegmentEnabled, teacherSegmentEnabled, parentB2CSegmentEnabled } =
    getBookMonetizationSegments(segmentKeysForBooksMonetization);

  if (parentGuardian && hasAnyOneOfferingAccess) {
    return parentSegmentEnabled || parentB2CSegmentEnabled;
  }

  // There might be a delay in assigning book monetization abtest to parent but multi pack plan code gets assigned to that student
  // so to handle that edge case have added both user types check
  // apart from the above mentioned edge case all other cases would also be handled with it
  return parentSegmentEnabled || parentB2CSegmentEnabled || teacherSegmentEnabled;
};
